import { Paginate, ResponseError } from 'modules/common/types';
import { UseFormSetError } from 'react-hook-form';
import { toast } from 'react-toastify';

export const getAllIds = (
  selectedRow: Record<string, boolean>,
  data: Paginate<any> | undefined
) => {
  if (!data) {
    return;
  }

  const ids = Object.keys(selectedRow)
    .map((index) => data.items[parseInt(index)].id)
    .filter((id) => id !== undefined) as string[];

  return ids;
};

export const getAllIdsNonPaginate = (
  selectedRow: Record<string, boolean>,
  data: Array<any> | undefined,
  idObject?: string
) => {
  if (!data) {
    return;
  }

  const ids = Object.keys(selectedRow)
    .map((index) =>
      idObject ? data[parseInt(index)][idObject] : data[parseInt(index)].id
    )
    .filter((id) => id !== undefined) as string[];

  return ids;
};

export const setValidationError = (
  error: ResponseError,
  setError: UseFormSetError<any>
) => {
  if (error.errors?.code === 422) {
    const validationMessages = error.errors.message;
    console.log(validationMessages);
    if (validationMessages) {
      for (const key in validationMessages) {
        setError(key as any, {
          type: 'server',
          message: validationMessages[key],
        });
      }
    }
  }
};

export const showErrorMessage = (
  error: ResponseError,
  autoClose: 4000 | number = 4000
) => {
  if (error.errors) {
    Object.values(error.errors.message).forEach((message) => {
      toast(message, {
        autoClose: autoClose,
      });
    });
  }
};

export const randomUniqueId = () => {
  return Math.random().toString(36).substring(2, 9);
};

// ex: ["HO6FI001"] -> HO6FI001
export const removeSquareBracket = (characters: string | undefined) => {
  if (!characters) {
    return '';
  }
  return characters.replace('["', '').replace('"]', '');
};

// Ex: 2021-09-22 10:34:31 -> 2021/09/22 10:34
export const formatTime = (date: string) => {
  const splittedDate = date.split(' ');
  const formattedDate = splittedDate[0].split('-').join('/');
  const formattedTime = splittedDate[1].substring(0, 5);
  const result = `${formattedDate} ${formattedTime}`;
  return result;
};

export const parseUndescoreString = (text: string) => {
  return text.replace('_', ' ');
};

export const parseMenuToUrl = (menu: string) => {
  const url =
    menu === 'accrued'
      ? '/accrued'
      : menu === 'accrued monitoring'
      ? '/accrued-monitoring'
      : menu === 'accrued last month'
      ? '/accruedlastmonth'
      : menu === 'ewgp period'
      ? '/config/ewgp-period'
      : menu === 'coa mapping'
      ? '/config/coamapping'
      : menu === 'coa mapping subheader'
      ? '/config/coamapping/subheader'
      : menu === 'coa mapping header'
      ? '/config/coamapping/header'
      : menu === 'coa mapping header group'
      ? '/config/coamapping/header-group'
      : menu === 'working paper'
      ? '/workingpaper'
      : menu === 'proportional cost'
      ? '/proportionalcost'
      : menu === 'ibs'
      ? '/ibs'
      : menu === 'pmr90f'
      ? '/pmr'
      : menu === 'acp tactys'
      ? '/acptactys'
      : menu === 'mapping'
      ? '/mapping'
      : menu === 'accrued revenue'
      ? '/accrued-revenue'
      : menu === 'last month'
      ? '/last-month'
      : menu === 'monitoring generate report'
      ? '/workingpaper/monitoring'
      : menu === 'report ewgp'
      ? '/ewgp-report'
      : '/';
  return url;
};
